import React from "react";
import { Label, HiddenCheckbox, StyledCheckbox, Svg, Polyline, LabelText } from "./styles";

const Checkbox = (props: any) => {
  const { label, checked, disabled = false, loading = false, style, ...rest } = props;

  return (
    <Label style={style} disabled={disabled} loading={loading ? 1 : 0}>
      <HiddenCheckbox checked={checked} disabled={disabled || loading ? true : false} {...rest} />
      <StyledCheckbox checked={checked} disabled={disabled} loading={loading ? 1 : 0}>
        <Svg width="20px" height="20px" viewBox="0 0 20 20">
          <Polyline points="4 11 8 15 16 6" checked={checked} disabled={disabled} loading={loading ? 1 : 0}></Polyline>
        </Svg>
      </StyledCheckbox>
      {label && (
        <LabelText disabled={disabled} loading={loading ? 1 : 0}>
          {label}
        </LabelText>
      )}
    </Label>
  );
};

export default Checkbox;
