import React, { FC, useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import Tooltip from "../Tooltip";
import downloadFile from "../../util/downloadFile";
import { stringify } from "csv-stringify/browser/esm/sync";
import copyToClipboard from "../../util/copyToClipboard";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { isAdminOrUser } from "../../util/checkRole";
import { MenuList, MenuButton, DangerMenuButton } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";
import MoreIcon from "../../svgs/MoreIcon";
import Badge from "../Badge";
import { PaginationState, ColumnDef, SortingState, ColumnFiltersState } from "@tanstack/react-table";
import { getTableFilters } from "../../util/urlParamFilters";
import { contentsTableDefaults, getContentsTableSettings, saveContentsTableSettings } from "../../services/localStorage";
import { useDebounce } from "use-debounce";
import Tag from "../Tag";
import sortTags from "../../util/sortTags";
import { getAllContents } from "../../services/contents";
import RemoveContentsModal from "../RemoveContentsModal";
import NewTable from "../NewTable";
import EditContentsModal from "../EditContentsModal";

type Contents = {
  id: string;
  identifier: string;
  manifestId: string;
  manifestName: string;
  organisationId: string;
  organisationName: string;
  name: string;
  description: string;
  dateAddedUnix: number;
  dateAdded: string;
  dateRemovedUnix: number;
  dateRemoved: string;
  userAdded: string;
  userRemoved: string;
  placeAddedId: number;
  placeAddedName: string;
  placeRemovedId: string;
  placeRemovedName: string;
  contentTags: string;
  removed: boolean;
};

const ContentsTable: FC<any> = ({ manifestId }) => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateAddedUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getContentsTableSettings() ? getContentsTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If manifestId is not empty add filters to only show contents for that manifest
  const manifestPageFilters = (filters: any) => {
    if (manifestId == null || manifestId === "") return filters;

    const filtersCopy = JSON.parse(JSON.stringify(filters));
    filtersCopy.push({ id: "manifestId", value: { type: "number", value: manifestId } });

    return filtersCopy;
  };

  const fetchContents = () => {
    setDataLoading(true);
    setDataErr("");

    getAllContents(source, true, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(manifestPageFilters(columnFiltersDebounced)) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getAllContents(source, true, { orderBy: sorting, filters: getTableFilters(manifestPageFilters(columnFiltersDebounced)) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Contents List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = [
      "Status",
      "Manifest ID",
      "Contents ID",
      "Name",
      "Description",
      "Content Tags",
      "Date Added",
      "Date Removed",
      "Place Added",
      "Place Removed",
      "User Added",
      "User Removed",
    ];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.removed ? "Removed" : "Current",
          row.manifestId,
          row.identifier,
          row.name,
          row.description,
          row.contentTags
            .sort(sortTags)
            .map((tag: any) => tag.name)
            .join(", "),
          row.dateAddedUnix !== undefined ? moment.unix(row.dateAddedUnix).format(long_datetime) : "",
          row.dateRemovedUnix !== undefined ? moment.unix(row.dateRemovedUnix).format(long_datetime) : "",
          row.placeAddedName,
          row.placeRemovedName,
          row.userAdded,
          row.userRemoved,
        ];

        return rows;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<Contents>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.removed) return <></>;
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedItem(row.original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Item
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedItem(row.original);
                          setRemoveModalOpen(true);
                        }}
                      >
                        Remove Item
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Item</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <DangerMenuButton disabled={true}>Remove Item</DangerMenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Status",
        accessorKey: "removed",
        cell: (props: any) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Badge title={props.getValue() ? "Removed" : "Current"} background={props.getValue() ? color.danger_dark[2] : color.success_dark[2]}>
                {props.getValue() ? "Removed" : "Current"}
              </Badge>
            </div>
          );
        },
        meta: {
          filterType: "select",
          loadOptionsKey: "removed",
          selectKey: "value",
          selectOptions: [
            { value: true, label: "Removed", colour: color.danger_dark[2] },
            { value: false, label: "Current", colour: color.success_dark[2] },
          ],
        },
        filterFn: undefined,
        size: 160,
      },
      {
        header: "Manifest",
        accessorKey: "manifestName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/manifests/${props.row.original.manifestId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        header: "Contents ID",
        accessorKey: "identifier",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 200,
      },
      {
        header: "Name",
        accessorKey: "name",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 200,
      },
      {
        header: "Description",
        accessorKey: "description",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        accessorKey: "contentTags",
        cell: (props: any) =>
          props.row.original.contentTags ? (
            props.row.original.contentTags
              .sort(sortTags)
              .map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        header: "Content Tags",
        enableSorting: false,
        meta: {
          filterType: "autoComplete",
          filterKey: "contentTags.name",
          loadOptionsKey: "contentTags",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Date Added",
        accessorKey: "dateAddedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Date Removed",
        accessorKey: "dateRemovedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Place Added",
        accessorKey: "placeAddedName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.placeAddedId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Place Removed",
        accessorKey: "placeRemovedName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.placeRemovedId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "User Added",
        accessorKey: "userAdded",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "User Removed",
        accessorKey: "userRemoved",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchContents}
        fetchCsv={fetchCsv}
        defaultTableSettings={contentsTableDefaults}
        getTableSettings={getContentsTableSettings}
        saveTableSettings={saveContentsTableSettings}
        dataTypeName="Contents"
        emptyDataMsg="Create a manifest and add contents to get started"
      />
      {editModalOpen && (
        <EditContentsModal
          manifestId={selectedItem?.manifestId}
          item={selectedItem}
          onSuccess={fetchContents}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
        />
      )}
      {removeModalOpen && (
        <RemoveContentsModal contentsId={selectedItem?.id} onSuccess={fetchContents} modalOpen={removeModalOpen} setModalOpen={setRemoveModalOpen} />
      )}
    </>
  );
};

export default ContentsTable;
