import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getPickupRequestsTableSettings, pickupRequestsTableDefaults, savePickupRequestsTableSettings } from "../../services/localStorage";
import { deletePickupRequest, fetchPickupRequests } from "../../services/pickupRequests";
import MoreIcon from "../../svgs/MoreIcon";
import addOrUpdate from "../../util/addOrUpdate";
import { isBinaryLoop } from "../../util/checkDomain";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { googleMapsLink } from "../../util/googleMapsLink";
import { kegOrTracker } from "../../util/kegOrTracker";
import { getTableFilters } from "../../util/urlParamFilters";
import Badge from "../Badge";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditPickupRequestModal from "../EditPickupRequestModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { PrimaryIconBtn } from "../IconButtons";
import MarkAsCollectedModel from "../MarkAsCollectedModel";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type PickupRequest = {
  id: number;
  status: string;
  trackerId: string;
  dateRequested: Date;
  dateRequestedUnix: number;
  datePickedUp: Date | null;
  datePickedUpUnix: number | null;
  requestedBy: string;
  pickedUpBy: string | null;
  placeId: number;
  placeName: string;
  latitude: number;
  longitude: number;
  notes: string;
  pickupRequestTypeName: string;
  pickupRequestTypeColour: string;
};

const PickupRequestsTable: FC<any> = () => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateRequestedUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: "status",
      value: {
        selectKey: "value",
        type: "select",
        selected: {
          value: "Requested",
          label: "Requested",
          colour: color.danger[2],
          comparisonOperator: "eq",
        },
        comparisonOperator: "eq",
      },
    },
  ]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [markAsCollectedModalOpen, setMarkAsCollectedModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getPickupRequestsTableSettings() ? getPickupRequestsTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getPickupRequests = () => {
    setDataLoading(true);
    fetchPickupRequests(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    fetchPickupRequests(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Pickup Request List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = [
      "Status",
      "Tracker Id",
      "Date Requested",
      "Requested By",
      "Date Collected",
      "Collected By",
      isBinaryLoop() ? "Contents" : "Pickup Request Type",
      "Place",
      "Latitude",
      "Longitude",
      "Notes",
    ];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.status,
          row.trackerId,
          row.dateRequestedUnix != null ? moment.unix(row.dateRequestedUnix).format(long_datetime) : "",
          row.requestedBy,
          row.datePickedUpUnix != null ? moment.unix(row.datePickedUpUnix).format(long_datetime) : "",
          row.pickedUpBy,
          row.pickupRequestTypeName,
          row.placeName,
          row.latitude,
          row.longitude,
          row.notes,
        ];

        return rows;
      }, []),
    ];
  };

  const updateTable = (newData: any) => {
    setData((prev: any) => addOrUpdate(prev, newData, "id"));
    setSorting((prev) => [...prev]); // This will trigger sorting recalculation
  };

  const columns = React.useMemo<ColumnDef<PickupRequest>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      {row.original.datePickedUpUnix == null && (
                        <MenuButton
                          onClick={() => {
                            setSelectedRequest(row.original);
                            setMarkAsCollectedModalOpen(true);
                          }}
                        >
                          Mark as Collected
                        </MenuButton>
                      )}
                      <MenuButton
                        onClick={() => {
                          setSelectedRequest(row.original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Pickup Request
                      </MenuButton>
                      {row.original.datePickedUpUnix == null && (
                        <DangerMenuButton
                          onClick={() => {
                            setSelectedRequest(row.original);
                            setDeleteModalOpen(true);
                          }}
                        >
                          Delete Pickup Request
                        </DangerMenuButton>
                      )}
                    </>
                  ) : (
                    <>
                      {row.original.datePickedUpUnix == null && (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Mark as Collected</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Pickup Request</MenuButton>
                        </div>
                      </Tooltip>
                      {row.original.datePickedUpUnix == null && (
                        <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                          <div
                            style={{
                              cursor: "not-allowed",
                              userSelect: "none",
                            }}
                          >
                            <MenuButton disabled={true}>Delete Pickup Request</MenuButton>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        accessorKey: "status",
        cell: (props: any) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Badge title={props.getValue()} background={props.getValue() === "Collected" ? color.success_dark[2] : color.danger_dark[2]}>
              {props.getValue()}
            </Badge>
          </div>
        ),
        header: "Status",
        meta: {
          filterType: "select",
          loadOptionsKey: "status",
          selectKey: "value",
          selectOptions: [
            { value: "Collected", label: "Collected", colour: color.success[2], comparisonOperator: "eq" },
            { value: "Requested", label: "Requested", colour: color.danger[2], comparisonOperator: "eq" },
          ],
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Tracker ID",
        accessorKey: "trackerId",
        cell: (props: any) => {
          const url = kegOrTracker("kegs", "trackers");
          return (
            <Link title={props.getValue()} to={`/${url}/${props.getValue()}`}>
              {props.getValue()}
            </Link>
          );
        },
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Date Requested",
        accessorKey: "dateRequestedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Requested By",
        accessorKey: "requestedBy",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Date Collected",
        accessorKey: "datePickedUpUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Collected By",
        accessorKey: "pickedUpBy",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        accessorKey: "pickupRequestTypeName",
        cell: (props: any) => (
          <>
            <ColoredDot color={props.row.original.pickupRequestTypeColour} />
            <span title={props.getValue()}>{props.getValue()}</span>
          </>
        ),
        header: isBinaryLoop() ? "Contents" : "Pickup Request Type",
        meta: {
          filterType: "autoComplete",
          filterKey: "pickupRequestTypeName",
          loadOptionsKey: "pickuprequesttypes",
        },
        filterFn: undefined,
        size: 210,
      },
      {
        accessorKey: "placeName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.placeId}`}>
            {props.getValue()}
          </Link>
        ),
        header: "Place",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 290,
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
        cell: (props: any) => googleMapsLink(props.getValue(), props.row.original.longitude, props.getValue()),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
        cell: (props: any) => googleMapsLink(props.row.original.latitude, props.getValue(), props.getValue()),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        accessorKey: "notes",
        header: "Notes",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 300,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={getPickupRequests}
        fetchCsv={fetchCsv}
        defaultTableSettings={pickupRequestsTableDefaults}
        getTableSettings={getPickupRequestsTableSettings}
        saveTableSettings={savePickupRequestsTableSettings}
        dataTypeName="Pickup Requests"
        emptyDataMsg="Create a pickup request above to get started"
        TableButtons={
          <div>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedRequest(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedRequest(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {editModalOpen && <EditPickupRequestModal request={selectedRequest} onSuccess={updateTable} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {markAsCollectedModalOpen && (
        <MarkAsCollectedModel id={selectedRequest.id} onSuccess={updateTable} modalOpen={markAsCollectedModalOpen} setModalOpen={setMarkAsCollectedModalOpen} />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Pickup Request"
          body={
            <>
              <span>Are you sure you want to delete this pickup request?</span>
            </>
          }
          successMsg="Pickup Request Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedRequest.id))}
          onClose={() => setSelectedRequest(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deletePickupRequest}
          serviceParams={[selectedRequest.trackerId]}
        />
      )}
    </>
  );
};

export default PickupRequestsTable;
