import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getManifestsTableSettings, manifestsTableDefaults, saveManifestsTableSettings } from "../../services/localStorage";
import { getManifests } from "../../services/manifests";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { kegOrTracker } from "../../util/kegOrTracker";
import { getTableFilters } from "../../util/urlParamFilters";
import Badge from "../Badge";
import { PrimaryBtn } from "../Buttons";
import CreateManifestModel from "../CreateManifestModel";
import EditManifestModel from "../EditManifestModel";
import { PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tooltip from "../Tooltip";
import { MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";
import sortTags from "../../util/sortTags";
import Tag from "../Tag";
import { InfoModal } from "../Modal";
import ContentsTable from "../ContentsTable";

type Manifest = {
  id: number;
  navifyTransportId: string;
  sensorId: string;
  name: string;
  description: string;
  startTime: string;
  startTimeUnix: number;
  endTime: string;
  endTimeUnix: number;
  deadlineTime: string;
  deadlineTimeUnix: number;
  startPlaceId: number;
  endPlaceId: number;
  targetPlaceId: number;
  startingUserId: string;
  endUserId: string;
  status: string;
  createdUserId: string;
  createdTime: string;
  createdTimeUnix: number;
  startPlaceName: string;
  endPlaceName: string;
  targetPlaceName: string;
  startingUserName: string;
  endUserName: string;
  createdUserName: string;
  contentsQuantity: number;
  contentTags: any[];
};

const ManifestsTable: FC<any> = () => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "createdTimeUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedManifest, setSelectedManifest] = useState<any>(undefined);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);
  const [contentsListModalOpen, setContentsListModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getManifestsTableSettings() ? getManifestsTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchData = () => {
    setDataLoading(true);
    setDataErr("");

    getManifests(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getManifests(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Manifest List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    let headers = [];

    headers = ["Name", "Status", "Tracker ID", "Created By", "Place Started", "Quantity", "Date Created"];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.name,
          row.status,
          row.sensorId,
          row.createdUserName,
          row.startPlaceName,
          row.contentsQuantity,
          row.createdTimeUnix ? moment.unix(row.createdTimeUnix).format(long_datetime) : "",
        ];

        return rows;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<Manifest>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <MenuButton
                      onClick={() => {
                        setSelectedManifest(row.original);
                        setEditModalOpen(true);
                      }}
                    >
                      Edit Manifest
                    </MenuButton>
                  ) : (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Edit Manifest</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Name",
        accessorKey: "name",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/manifests/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (props: any) => (
          <div style={{ textAlign: "center" }}>
            <Badge title={props.getValue()} background={props.getValue() === "Finished, ALERT" ? color.danger_dark[2] : color.success_dark[2]}>
              {props.getValue()}
            </Badge>
          </div>
        ),
        meta: {
          filterType: "select",
          loadOptionsKey: "pickup",
          selectKey: "value",
          selectOptions: [
            { value: "Not Started", label: "Not Started", colour: color.success_dark[2] },
            { value: "Started, OK", label: "Started, OK", colour: color.success_dark[2] },
            { value: "Finished, OK", label: "Finished, OK", colour: color.success_dark[2] },
            { value: "Finished, ALERT", label: "Finished, ALERT", colour: color.danger_dark[2] },
          ],
        },
        filterFn: undefined,
        size: 190,
      },
      {
        header: "Tracker ID",
        accessorKey: "sensorId",
        cell: (props: any) => {
          const url = kegOrTracker("kegs", "trackers");
          return (
            <Link title={props.getValue()} to={`/${url}/${props.getValue()}`}>
              {props.getValue()}
            </Link>
          );
        },
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Place Started",
        accessorKey: "startPlaceName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.startPlaceId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Place Ended",
        accessorKey: "endPlaceName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.endPlaceId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Target Destination",
        accessorKey: "targetPlaceName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.targetPlaceId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 190,
      },
      {
        header: "Contents Quantity",
        accessorKey: "contentsQuantity",
        cell: (props: any) =>
          props.getValue() > 0 ? (
            <div
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setSelectedManifest(props.row.original);
                setContentsListModalOpen(true);
              }}
            >
              {props.getValue()}
            </div>
          ) : (
            <span title={props.getValue()}>{props.getValue()}</span>
          ),
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 200,
      },
      {
        accessorKey: "contentTags",
        cell: (props: any) =>
          props.row.original.contentTags ? (
            props.row.original.contentTags
              .sort(sortTags)
              .map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        header: "Content Tags",
        enableSorting: false,
        meta: {
          filterType: "autoComplete",
          filterKey: "contentTags.name",
          loadOptionsKey: "contentTags",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        header: "Date Created",
        accessorKey: "createdTimeUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Date Started",
        accessorKey: "startTimeUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Date Ended",
        accessorKey: "endTimeUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Deadline",
        accessorKey: "deadlineTimeUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Created By",
        accessorKey: "createdUserName",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Started By",
        accessorKey: "startingUserName",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Ended By",
        accessorKey: "endUserName",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchData}
        fetchCsv={fetchCsv}
        defaultTableSettings={manifestsTableDefaults}
        getTableSettings={getManifestsTableSettings}
        saveTableSettings={saveManifestsTableSettings}
        dataTypeName="Manifests"
        emptyDataMsg="Create a manifest above to get started"
        TableButtons={
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn onClick={() => setCreateModalOpen(true)}>Create</PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn onClick={() => setCreateModalOpen(true)}>
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {createModalOpen && <CreateManifestModel onSuccess={fetchData} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />}
      {editModalOpen && <EditManifestModel manifest={selectedManifest} onSuccess={fetchData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {contentsListModalOpen && (
        <InfoModal
          isOpen={contentsListModalOpen}
          onClose={() => {
            setSelectedManifest(undefined);
            setContentsListModalOpen(false);
          }}
          size="lg"
          title="Manifest Contents"
          okayBtnText="Close"
          okayBtnProps={{ width: "100%" }}
          body={<ContentsTable manifestId={selectedManifest.id} />}
        />
      )}
    </>
  );
};

export default ManifestsTable;
