import { printCountryTemp, printLength, printPressure, printTemp, printVelocity } from "../../util/formatUnits";

/**********************************************************************************************/
/**************************************** CHART Y-AXIS ****************************************/
/**********************************************************************************************/

export const sampleYAxis = (i: number) => ({
  gridIndex: i,
  name: "Sample",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  max: 1,
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: false,
  },
  axisPointer: {
    show: false,
  },
});

export const moveYAxis = (i: number) => ({
  gridIndex: i,
  name: "Moved",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  max: 1,
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: false,
  },
  axisPointer: {
    show: false,
  },
});

export const eventsYAxis = (i: number) => ({
  gridIndex: i,
  name: "Events",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  max: 1,
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: false,
  },
  axisPointer: {
    show: false,
  },
});

export const placeYAxis = (i: number) => ({
  gridIndex: i,
  name: "Events",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  max: 1,
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: false,
  },
  axisPointer: {
    show: false,
  },
});

export const tempYAxis = (i: number) => ({
  gridIndex: i,
  name: "Temperature",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => printTemp(value, 0),
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => printTemp(value),
    },
  },
  axisLine: {
    show: true,
  },
});

export const publicTempYAxis = (i: number, country: any) => ({
  gridIndex: i,
  name: "Temperature",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => printCountryTemp(value, 0, country),
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => printCountryTemp(value, 2, country),
    },
  },
  axisLine: {
    show: true,
  },
});

export const voltageYAxis = (i: number) => ({
  gridIndex: i,
  name: "Voltage",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  max: (value: any) => {
    return (Math.ceil(value.max * 5) / 5).toFixed(2);
  },
  min: (value: any) => {
    return (Math.floor(value.min * 5) / 5).toFixed(2);
  },
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(2)} V`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(2)} V`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const supplyVoltageYAxis = (i: number) => ({
  gridIndex: i,
  name: "Supply Voltage",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(2)} V`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(2)} V`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const orientationYAxis = (i: number) => ({
  gridIndex: i,
  name: "Orientation",
  min: 0.5,
  max: 2.5,
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  axisLabel: {
    formatter: (value: any): string => (value === 2 ? "Up" : value === 1 ? "Down" : ""),
  },
  axisPointer: {
    show: false,
  },
  axisLine: {
    show: true,
  },
});

export const freshYAxis = (i: number) => ({
  gridIndex: i,
  name: "Freshness",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 2,
  min: 0,
  max: 100,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(1)} %`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(1)} %`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const lightYAxis = (i: number) => ({
  gridIndex: i,
  name: "Light",
  min: 0,
  max: 100,
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(1)} %`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(1)} %`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const runningYAxis = (i: number) => ({
  gridIndex: i,
  name: "Engine Running",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  axisLabel: {
    formatter: (value: any): string => (value ? "True" : "False"),
  },
  axisPointer: {
    show: false,
  },
  axisLine: {
    show: true,
  },
});

export const networkYAxis = (i: number) => ({
  gridIndex: i,
  name: "Network",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(1)}`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(1)}`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const input1YAxis = (i: number, label: string, unit: string, mode: string) => ({
  gridIndex: i,
  name: label ? label : "Input 1",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => (mode === "NTC Thermistor" ? printTemp(value, 3) : `${+value.toFixed(3)} ${unit ? unit : ""}`),
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => (mode === "NTC Thermistor" ? printTemp(value, 3) : `${+value.toFixed(3)} ${unit ? unit : ""}`),
    },
  },
  axisLine: {
    show: true,
  },
});

export const input2YAxis = (i: number, label: string, unit: string) => ({
  gridIndex: i,
  name: label ? label : "Input 2",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const input3YAxis = (i: number, label: string, unit: string, mode: string) => ({
  gridIndex: i,
  name: label ? label : "Input 3",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 3,
  axisLabel: {
    formatter: (value: any): string => (mode === "Air Velocity" ? printVelocity(value, 3) : `${+value.toFixed(3)} ${unit ? unit : ""}`),
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => (mode === "Air Velocity" ? printVelocity(value, 3) : `${+value.toFixed(3)} ${unit ? unit : ""}`),
    },
  },
  axisLine: {
    show: true,
  },
});

export const input4YAxis = (i: number, label: string, unit: string, mode: string) => ({
  gridIndex: i,
  name: label ? label : "Input 4",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 4,
  axisLabel: {
    formatter: (value: any): string => (mode === "Differential Pressure" ? printPressure(value, 3) : `${+value.toFixed(3)} ${unit ? unit : ""}`),
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => (mode === "Differential Pressure" ? printPressure(value, 3) : `${+value.toFixed(3)} ${unit ? unit : ""}`),
    },
  },
  axisLine: {
    show: true,
  },
});

export const input5YAxis = (i: number, label: string, unit: string) => ({
  gridIndex: i,
  name: label ? label : "Input 5",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 5,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const input6YAxis = (i: number, label: string, unit: string) => ({
  gridIndex: i,
  name: label ? label : "Input 6",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 6,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const input7YAxis = (i: number, label: string, unit: string) => ({
  gridIndex: i,
  name: label ? label : "Input 7",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 7,
  axisLabel: {
    formatter: (value: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${+value.toFixed(3)} ${unit ? unit : ""}`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const timeOfFlightYAxis = (i: number) => ({
  gridIndex: i,
  name: "Time Of Flight",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  splitNumber: 7,
  axisLabel: {
    formatter: (value: any): string => `${value} mm`,
  },
  axisPointer: {
    label: {
      formatter: ({ value }: any): string => `${value.toFixed(1)} mm`,
    },
  },
  axisLine: {
    show: true,
  },
});

export const onTapYAxis = (i: number) => ({
  gridIndex: i,
  name: "On Tap",
  nameLocation: "middle",
  nameGap: 60,
  position: "left",
  minInterval: 1,
  axisLabel: {
    formatter: (value: any): string => (value ? "True" : "False"),
  },
  axisPointer: {
    show: false,
  },
  axisLine: {
    show: true,
  },
});

/**********************************************************************************************/
/**************************************** CHART SERIES ****************************************/
/**********************************************************************************************/

export const sampleSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  barMinWidth: "2px",
  barMaxWidth: "2px",
  encode: {
    x: "ts",
    y: "sample",
  },
  id: "sample",
  itemStyle: {
    color: (e: any) => {
      const val = e.data.sample;
      // Tracker sample
      if (val === 1) return colors.sample;
      // Tracker sample historic
      else if (val === 2) return colors.historic;
      // Authenticated user action or QR scan
      else if (val === 3) return colors.userPrivate;
      // Public phone QR scan
      else if (val === 4) return colors.userPublic;
      else return null;
    },
  },
  legendHoverLink: false,
  name: "Sample",
  sampling,
  type: "bar",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const moveSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  barMinWidth: "2px",
  barMaxWidth: "2px",
  connectNulls: true,
  encode: {
    x: "ts",
    y: "move",
  },
  id: "move",
  legendHoverLink: false,
  name: "Moved",
  sampling,
  symbol: "none",
  type: "bar",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const eventsSeries = (i: number, sampling: string, opacity: number, colors: any, events: any) => ({
  itemStyle: {
    color: (params: any) => params.value[1].eventColour,
  },
  dimensions: ["eventDate", "event", "const"],
  encode: {
    x: "eventDate",
    y: "const",
  },
  id: "events",
  progressive: 0,
  legendHoverLink: false,
  name: "Events",
  sampling,
  symbolSize: 6,
  type: "scatter",
  xAxisIndex: i,
  yAxisIndex: i,
  data: events,
});

export const placeSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  lineStyle: {
    width: 0,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "const",
  },
  id: "place",
  legendHoverLink: false,
  name: "Events",
  sampling,
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const tempSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "temp",
  },
  id: "temp",
  legendHoverLink: false,
  name: "Temperature",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const measuredTempSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "temp",
  },
  id: "temp",
  legendHoverLink: false,
  name: "Temperature",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "circle",
  symbolSize: 6,
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const appliedTempSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  encode: {
    x: "ts",
    y: "appliedTemp",
  },
  id: "appliedTemp",
  progressive: 0,
  legendHoverLink: false,
  name: "Applied Temperature",
  sampling,
  symbolSize: 6,
  type: "scatter",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const publicTempSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "temp",
  },
  id: "temp",
  legendHoverLink: false,
  name: "Temperature",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const voltageSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  connectNulls: true,
  encode: {
    x: "ts",
    y: "voltage",
  },
  id: "voltage",
  legendHoverLink: false,
  name: "Voltage",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const loadVoltageSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  connectNulls: true,
  encode: {
    x: "ts",
    y: "loadVoltage",
  },
  id: "loadVoltage",
  legendHoverLink: false,
  name: "Load Voltage",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const supplyVoltageSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "supplyVoltage",
  },
  id: "supplyVoltage",
  legendHoverLink: false,
  name: "Supply Voltage",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const orientationSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: false,
  encode: {
    x: "ts",
    y: "orientation",
  },
  id: "orientation",
  legendHoverLink: false,
  name: "Orientation",
  sampling,
  step: "end",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const freshSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "fresh",
  },
  id: "fresh",
  legendHoverLink: false,
  name: "Freshness",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const lightSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "light",
  },
  id: "light",
  legendHoverLink: false,
  name: "Light",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const lightInterruptSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  encode: {
    x: "ts",
    y: "lightInterrupt",
  },
  id: "lightInterrupt",
  progressive: 0,
  legendHoverLink: false,
  name: "Light Interrupt",
  sampling,
  symbolSize: (val: any) => {
    return val.lightInterrupt ? 6 : 0;
  },
  type: "scatter",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const runningSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "running",
  },
  id: "running",
  legendHoverLink: false,
  name: "Engine Running",
  sampling,
  step: "end",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const networkSeries = (i: number, sampling: string, opacity: number, colors: any) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "network",
  },
  id: "network",
  legendHoverLink: false,
  name: "Network",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input1Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input1",
  },
  id: "input1",
  legendHoverLink: false,
  name: label ? label : "Input 1",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input2Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input2",
  },
  id: "input2",
  legendHoverLink: false,
  name: label ? label : "Input 2",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input3Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input3",
  },
  id: "input3",
  legendHoverLink: false,
  name: label ? label : "Input 3",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input4Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input4",
  },
  id: "input4",
  legendHoverLink: false,
  name: label ? label : "Input 4",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input5Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input5",
  },
  id: "input5",
  legendHoverLink: false,
  name: label ? label : "Input 5",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input6Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input6",
  },
  id: "input6",
  legendHoverLink: false,
  name: label ? label : "Input 6",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const input7Series = (i: number, sampling: string, opacity: number, label: string) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "input7",
  },
  id: "input7",
  legendHoverLink: false,
  name: label ? label : "Input 7",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const timeOfFlightSeries = (i: number, sampling: string, opacity: number) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: true,
  encode: {
    x: "ts",
    y: "timeOfFlight",
  },
  id: "timeOfFlight",
  legendHoverLink: false,
  name: "Time Of flight",
  sampling,
  smooth: true,
  smoothMonotone: "x",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

export const onTapSeries = (i: number, sampling: string, opacity: number) => ({
  areaStyle: {
    opacity,
  },
  connectNulls: false,
  encode: {
    x: "ts",
    y: "coupled",
  },
  id: "coupled",
  legendHoverLink: false,
  name: "On Tap",
  sampling,
  step: "end",
  symbol: "none",
  type: "line",
  xAxisIndex: i,
  yAxisIndex: i,
});

/**********************************************************************************************/
/*************************************** CHART TOOLTIPS ***************************************/
/**********************************************************************************************/

export const sampleTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value === 1) return "<b>Tracker Sample</b><br />" + tooltip;
  else if (value === 2) return "<b>Historic Tracker Sample</b><br />" + tooltip;
  else if (value === 3) return "<b>Authenticated User Action</b><br />" + tooltip;
  else if (value === 4) return "<b>Public Scan</b><br />";
  else return "<b>Tracker Log</b><br />" + tooltip;
};

export const moveTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Moved: ${value ? "True<br />" : "False<br />"}`;
  else return tooltip;
};

export const eventsTooltip = (series: any, tooltip: string, meta: any) => {
  const event = series.value[1];
  let text = event.text ? event.text : "";
  text += event.placeId && event.placeName ? ` ${event.placeName}` : "";
  text += event.beerId && event.beerName ? ` ${event.beerName}` : "";
  text += event.latitude && event.longitude ? ` ${event.addressText}` : "";
  text += event.locationType && event.accuracy ? ` (${event.locationType} accuracy ${printLength(event.accuracy)})` : "";
  const marker = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${event.eventColour};"></span>`;
  return tooltip + `${marker}${event.eventType || "Event"}: ${text}<br />`;
};

export const placeTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const colour = series.value["placeGroupColour"];
  if (value != undefined)
    return (
      tooltip +
      `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${colour};"></span>Place: ${value}<br />`
    );
  else return tooltip;
};

export const tempTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Temperature: ${printTemp(value)}<br />`;
  else return tooltip;
};

export const appliedTempTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Applied Temperature: ${printTemp(value)}<br />`;
  else return tooltip;
};

export const publicTempTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const country = "";
  if (value != undefined) return tooltip + `${series.marker}Temperature: ${printCountryTemp(value, 2, country)}<br />`;
  else return tooltip;
};

export const voltageTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Voltage: ${value.toFixed(2)} V<br />`;
  else return tooltip;
};

export const loadVoltageTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Load Voltage: ${value.toFixed(2)} V<br />`;
  else return tooltip;
};

export const supplyVoltageTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Supply Voltage: ${value.toFixed(2)} V<br />`;
  else return tooltip;
};

export const orientationTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Orientation: ${value === 2 ? "Up<br />" : value === 1 ? "Down<br />" : "Unknown<br />"}`;
  else return tooltip;
};

export const freshTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Freshness: ${value.toFixed(1)} %<br />`;
  else return tooltip;
};

export const lightTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Light: ${value.toFixed(1)} %<br />`;
  else return tooltip;
};

export const lightInterruptTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Light Interrupt: ${value ? "True" : "False"}<br />`;
  else return tooltip;
};

export const runningTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Engine Running: ${value ? "True<br />" : "False<br />"}`;
  else return tooltip;
};

export const networkTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Network: ${value.toFixed(1)}<br />`;
  else return tooltip;
};

export const input1Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const mode = meta.mode;
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined)
    return tooltip + mode === "NTC Thermistor"
      ? `${series.marker}${label ? label : "Input 1"}: ${printTemp(value, 3)}<br />`
      : `${series.marker}${label ? label : "Input 1"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const input2Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined) return tooltip + `${series.marker}${label ? label : "Input 2"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const input3Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const mode = meta.mode;
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined)
    return tooltip + mode === "Air Velocity"
      ? `${series.marker}${label ? label : "Input 3"}: ${printVelocity(value, 3)}<br />`
      : `${series.marker}${label ? label : "Input 3"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const input4Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const mode = meta.mode;
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined)
    return tooltip + mode === "Differential Pressure"
      ? `${series.marker}${label ? label : "Input 4"}: ${printPressure(value, 3)}<br />`
      : `${series.marker}${label ? label : "Input 4"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const input5Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined) return tooltip + `${series.marker}${label ? label : "Input 5"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const input6Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined) return tooltip + `${series.marker}${label ? label : "Input 6"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const input7Tooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  const label = meta.label;
  const unit = meta.unit;
  if (value != undefined) return tooltip + `${series.marker}${label ? label : "Input 7"}: ${value.toFixed(3)} ${unit ? unit : ""}<br />`;
  else return tooltip;
};

export const timeOfFlightTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}Time Of flight: ${value.toFixed(1)} mm<br />`;
  else return tooltip;
};

export const onTapTooltip = (series: any, tooltip: string, meta: any) => {
  const value = series.value[series.seriesId];
  if (value != undefined) return tooltip + `${series.marker}On Tap: ${value ? "True<br />" : "False<br />"}`;
  else return tooltip;
};
