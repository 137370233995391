import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useRef, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import Table from "../../Table";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import { PrimaryBtn } from "../../Buttons";
import { StatBlock, StatLabel, StatValue } from "../styles";
import { Title, Subtitle } from "../../PageTitle/styles";
import { fetchTripReport } from "../../../services/tripReport";
import Drawer from "rc-drawer";
import Cross from "../../../svgs/Cross";
import moment from "moment";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import { AsyncSelect, Select } from "../../Select";
import { fetchAutoComplete } from "../../../services/autoComplete";
import Bold from "../../Bold";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import { humaniseMinutes } from "../../../util/humaniseDurations";
import { TemperatureSpan } from "../../ReportsScreen/styles";
import { DangerAlert } from "../../Alerts";
import FlatpickrPicker from "../../FlatpickrPicker";
import { printFixedTemp, printTempUnit } from "../../../util/formatUnits";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import getParameterByName from "../../../util/getParamByName";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { ICountry, countries } from "countries-list";
import { FormError } from "../../FormComponents";
import PlaceListModal from "../../PlaceListModal";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { IconContext } from "react-icons";

const formatCountries = () => {
  const list = Object.values(countries).map((value) => {
    const country = value as ICountry;
    return {
      value: country.name,
      label: country.name,
    };
  });
  return list.sort((a, b) => a.value.localeCompare(b.value));
};

const formatDataToCsv = (tableRef: any, long_datetime: string) => {
  const headers = [
    kegsOrTrackers("Keg", "Tracker"),
    "Start Date",
    "End Date",
    "Trip Duration",
    "Place Count",
    `Min Temperature (${printTempUnit()})`,
    `Average Temperature (${printTempUnit()})`,
    `Max Temperature (${printTempUnit()})`,
  ];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.sensorId,
        moment.unix(row.startDate).format(long_datetime),
        moment.unix(row.endDate).format(long_datetime),
        humaniseMinutes(row.minutes),
        row.placeCount,
        printFixedTemp(row.minTemp, 1),
        printFixedTemp(row.avgTemp, 1),
        printFixedTemp(row.maxTemp, 1),
      ];
    }, []),
  ];
};

const RouteAudit: FC<any> = () => {
  const { color, short_date, short_datetime, long_datetime } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
  const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;

  const [data, setData] = useState<any>({
    trips: [],
    meta: {
      trips: undefined,
      sensors: undefined,
      minMinutes: undefined,
      avgMinutes: undefined,
      maxMinutes: undefined,
    },
  });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [placeList, setPlaceList] = useState<any>([]);
  const [placeListModalOpen, setPlaceListModalOpen] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true);

  // Report filters
  const [startPlace, setStartPlace] = useState<any>(null);
  const [startPlaceType, setStartPlaceType] = useState<any>(null);
  const [startCountry, setStartCountry] = useState<any>(null);
  const [visitedCountry, setVisitedCountry] = useState<any>(null);
  const [endPlace, setEndPlace] = useState<any>(null);
  const [endPlaceType, setEndPlaceType] = useState<any>(null);
  const [endCountry, setEndCountry] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(startDateParam != null ? Number(startDateParam) * 1000 : moment().subtract(3, "months").valueOf());
  const [endDate, setEndDate] = useState<any>(endDateParam != null ? Number(endDateParam) * 1000 : moment().valueOf());

  const [appliedFilters, setAppliedFilters] = useState<number>(0);
  const [filterErrors, setFilterErrors] = useState<any>({});

  const [autoCompletePlaces, setAutoCompletePlaces] = useState<any>(undefined);
  const [autoCompletePlaceTypes, setAutoCompletePlaceTypes] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const countryList = useMemo(() => formatCountries(), []);

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all autoComplete fields to use for matching params
  // e.g. match "?place-types=Venue" to {value: "a980bcaa-7ede-45b5-8ced-63e10284f994", label: "Venue", colour: "#c16807",…}
  useEffect(() => {
    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;
    const startPlaceParam = getParameterByName("start-place", location.search) != null ? getParameterByName("start-place", location.search) : null;
    const startPlaceTypeParam =
      getParameterByName("start-place-type", location.search) != null ? getParameterByName("start-place-type", location.search) : null;
    const startCountryParam = getParameterByName("start-country", location.search) != null ? getParameterByName("start-country", location.search) : null;
    const visitedCountryParam = getParameterByName("visited-country", location.search) != null ? getParameterByName("visited-country", location.search) : null;
    const endPlaceParam = getParameterByName("end-place", location.search) != null ? getParameterByName("end-place", location.search) : null;
    const endPlaceTypeParam = getParameterByName("end-place-type", location.search) != null ? getParameterByName("end-place-type", location.search) : null;
    const endCountryParam = getParameterByName("end-country", location.search) != null ? getParameterByName("end-country", location.search) : null;

    fetchAutoComplete("places", "").then((response) => {
      if (startPlaceParam != null) {
        setStartPlace(response.find((place: any) => startPlaceParam == place.value));
      }
      if (endPlaceParam != null) {
        setEndPlace(response.find((place: any) => endPlaceParam == place.value));
      }
      setAutoCompletePlaces(response);
    });

    setStartCountry(countryList.find((country: any) => startCountryParam == country.value));
    setVisitedCountry(countryList.find((country: any) => visitedCountryParam == country.value));

    fetchAutoComplete("placetypes", "").then((response) => {
      if (startPlaceTypeParam != null) {
        setStartPlaceType(response.find((type: any) => startPlaceTypeParam == type.value));
      }
      if (endPlaceTypeParam != null) {
        setEndPlaceType(response.find((type: any) => endPlaceTypeParam == type.value));
      }
      setAutoCompletePlaceTypes(response);
    });

    setEndCountry(countryList.find((country: any) => endCountryParam == country.value));

    if (
      startDateParam != null &&
      endDateParam != null &&
      (startPlaceParam != null || startPlaceTypeParam != null) &&
      (endPlaceParam != null || endPlaceTypeParam != null)
    ) {
      setFiltersOpen(false);
      fetchReport(
        startPlaceParam,
        startPlaceTypeParam,
        startCountryParam,
        visitedCountryParam,
        endPlaceParam,
        endPlaceTypeParam,
        endCountryParam,
        Number(startDateParam) * 1000,
        Number(endDateParam) * 1000
      );
    }
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let selectedStartPlace = undefined;
    let selectedStartPlaceType = undefined;
    let selectedStartCountry = undefined;
    let selectedVisitedCountry = undefined;
    let selectedEndPlace = undefined;
    let selectedEndPlaceType = undefined;
    let selectedEndCountry = undefined;
    let selectedStartDate = undefined;
    let selectedEndDate = undefined;

    if (autoCompletePlaces != null) {
      const startPlaceParam = getParameterByName("start-place", location.search) != null ? getParameterByName("start-place", location.search) : null;
      selectedStartPlace = autoCompletePlaces.find((place: any) => startPlaceParam == place.value);
      setStartPlace(selectedStartPlace);
    }

    if (autoCompletePlaceTypes != null) {
      const startPlaceTypeParam =
        getParameterByName("start-place-type", location.search) != null ? getParameterByName("start-place-type", location.search) : null;
      selectedStartPlaceType = autoCompletePlaceTypes.find((type: any) => startPlaceTypeParam == type.value);
      setStartPlaceType(selectedStartPlaceType);
    }

    const startCountryParam = getParameterByName("start-country", location.search) != null ? getParameterByName("start-country", location.search) : null;
    selectedStartCountry = countryList.find((country: any) => startCountryParam == country.value);
    setStartCountry(selectedStartCountry);

    const visitedCountryParam = getParameterByName("visited-country", location.search) != null ? getParameterByName("visited-country", location.search) : null;
    selectedVisitedCountry = countryList.find((country: any) => visitedCountryParam == country.value);
    setVisitedCountry(selectedVisitedCountry);

    if (autoCompletePlaces != null) {
      const endPlaceParam = getParameterByName("end-place", location.search) != null ? getParameterByName("end-place", location.search) : null;
      selectedEndPlace = autoCompletePlaces.find((place: any) => endPlaceParam == place.value);
      setEndPlace(selectedEndPlace);
    }

    if (autoCompletePlaceTypes != null) {
      const endPlaceTypeParam = getParameterByName("end-place-type", location.search) != null ? getParameterByName("end-place-type", location.search) : null;
      selectedEndPlaceType = autoCompletePlaceTypes.find((type: any) => endPlaceTypeParam == type.value);
      setEndPlaceType(selectedEndPlaceType);
    }

    const endCountryParam = getParameterByName("end-country", location.search) != null ? getParameterByName("end-country", location.search) : null;
    selectedEndCountry = countryList.find((country: any) => endCountryParam == country.value);
    setEndCountry(selectedEndCountry);

    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    if (startDateParam != null) {
      selectedStartDate = Number(startDateParam) * 1000;
      setStartDate(selectedStartDate);
    }

    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;
    if (endDateParam != null) {
      selectedEndDate = Number(endDateParam) * 1000;
      setEndDate(selectedEndDate);
    }

    if (!filtersLoading) {
      fetchReport(
        selectedStartPlace?.value,
        selectedStartPlaceType?.value,
        selectedStartCountry?.value,
        selectedVisitedCountry?.value,
        selectedEndPlace?.value,
        selectedEndPlaceType?.value,
        selectedEndCountry?.value,
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [location]);

  // After autoCompletes are initially set on mount, update filtersLoading to false
  useEffect(() => {
    if (autoCompletePlaces != null && autoCompletePlaceTypes != null) {
      setFiltersLoading(false);
    }
  }, [autoCompletePlaces, autoCompletePlaceTypes]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (startPlace != null) {
      newQuery["start-place"] = startPlace?.value;
    } else if (autoCompletePlaces != null) {
      delete newQuery["start-place"];
    }

    if (startPlaceType != null) {
      newQuery["start-place-type"] = startPlaceType?.value;
    } else if (autoCompletePlaceTypes != null) {
      delete newQuery["start-place-type"];
    }

    if (startCountry != null) {
      newQuery["start-country"] = startCountry?.value;
    } else {
      delete newQuery["start-country"];
    }

    if (visitedCountry != null) {
      newQuery["visited-country"] = visitedCountry?.value;
    } else {
      delete newQuery["visited-country"];
    }

    if (endPlace != null) {
      newQuery["end-place"] = endPlace?.value;
    } else if (autoCompletePlaces != null) {
      delete newQuery["end-place"];
    }

    if (endPlaceType != null) {
      newQuery["end-place-type"] = endPlaceType?.value;
    } else if (autoCompletePlaceTypes != null) {
      delete newQuery["end-place-type"];
    }

    if (endCountry != null) {
      newQuery["end-country"] = endCountry?.value;
    } else {
      delete newQuery["end-country"];
    }

    if (startDate != null) {
      newQuery["start-date"] = Math.round(startDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["start-date"];
    }

    if (endDate != null) {
      newQuery["end-date"] = Math.round(endDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["end-date"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (
    startPlaceParam?: any,
    startPlaceTypeParam?: any,
    startCountryParam?: any,
    visitedCountryParam?: any,
    endPlaceParam?: any,
    endPlaceTypeParam?: any,
    endCountryParam?: any,
    startDateParam?: any,
    endDateParam?: any
  ) => {
    setDataLoading(true);
    setDataErr("");

    const startPlaceArg = startPlaceParam != null ? startPlaceParam : startPlace?.value;
    const startPlaceTypeArg = startPlaceTypeParam != null ? startPlaceTypeParam : startPlaceType?.value;
    const startCountryArg = startCountryParam != null ? startCountryParam : startCountry?.value;
    const visitedCountryArg = visitedCountryParam != null ? visitedCountryParam : visitedCountry?.value;
    const endPlaceArg = endPlaceParam != null ? endPlaceParam : endPlace?.value;
    const endPlaceTypeArg = endPlaceTypeParam != null ? endPlaceTypeParam : endPlaceType?.value;
    const endCountryArg = endCountryParam != null ? endCountryParam : endCountry?.value;
    const startDateArg = startDateParam != null ? startDateParam : startDate;
    const endDateArg = endDateParam != null ? endDateParam : endDate;

    fetchTripReport(
      source,
      startPlaceArg,
      startPlaceTypeArg,
      startCountryArg,
      visitedCountryArg,
      endPlaceArg,
      endPlaceTypeArg,
      endCountryArg,
      startDateArg ? Math.round(startDateArg / 1000) : undefined,
      endDateArg ? Math.round(endDateArg / 1000) : undefined
    )
      .then((response) => {
        setData(response);
        setDataLoading(false);
        setFiltersOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData({
            trips: [],
            meta: {
              trips: undefined,
              sensors: undefined,
              minMinutes: undefined,
              avgMinutes: undefined,
              maxMinutes: undefined,
            },
          });
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    let filters = 0;

    if (startPlaceArg != null) filters++;
    if (startPlaceTypeArg != null) filters++;
    if (endPlaceArg != null) filters++;
    if (endPlaceTypeArg != null) filters++;
    if (startDateArg != null) filters++;
    if (endDateArg != null) filters++;

    setAppliedFilters(filters);
  };

  const columns = [
    {
      id: "sensorId",
      Header: kegsOrTrackers("Keg", "Tracker"),
      accessor: "sensorId",
      Cell: (props: any) => (
        <Link to={`/${kegsOrTrackers("kegs", "trackers")}/${props.value}?start=${props.original.startDateLink}&end=${props.original.endDateLink}`}>
          {props.value}
        </Link>
      ),
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      minWidth: 130,
    },
    {
      id: "startDate",
      Header: "Start Date",
      accessor: "startDate",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "endDate",
      Header: "End Date",
      accessor: "endDate",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "minutes",
      Header: "Trip Duration",
      accessor: "minutes",
      Cell: (props: any) => <span title={`${Math.round(props.value)} minutes`}>{humaniseMinutes(props.value)}</span>,
      minWidth: 180,
    },

    {
      id: "places",
      Header: "Places Visited",
      accessor: "places",
      Cell: (props: any) =>
        !props.value ? (
          0
        ) : props.value.length === 0 ? (
          props.value.length
        ) : (
          <div
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setPlaceList(props.value);
              setPlaceListModalOpen(true);
            }}
          >
            {props.value.length}
          </div>
        ),
      minWidth: 130,
    },
    {
      id: "placeCount",
      Header: "Place Count",
      accessor: "placeCount",
      Cell: (props: any) => <span title={props.value}>{props.value}</span>,
      minWidth: 85,
    },
    {
      Header: "Temperature",
      columns: [
        {
          id: "minTemp",
          Header: "Min",
          accessor: "minTemp",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
        {
          id: "avgTemp",
          Header: "Avg",
          accessor: "avgTemp",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
        {
          id: "maxTemp",
          Header: "Max",
          accessor: "maxTemp",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
      ],
    },
  ];

  const defaultSorted = [
    {
      id: "startDate",
      desc: true,
    },
  ];

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const isValidFilters = () => {
    let valid = true;

    if (startPlace == null && startPlaceType == null) {
      setFilterErrors((prev: any) => ({ ...prev, startPlace: "Required", startPlaceType: "Required" }));
      valid = false;
    } else {
      setFilterErrors((prev: any) => ({ ...prev, startPlace: undefined, startPlaceType: undefined }));
    }

    if (endPlace == null && endPlaceType == null) {
      setFilterErrors((prev: any) => ({ ...prev, endPlace: "Required", endPlaceType: "Required" }));
      valid = false;
    } else {
      setFilterErrors((prev: any) => ({ ...prev, endPlace: undefined, endPlaceType: undefined }));
    }

    if (startDate == null || endDate == null) {
      if (startDate == null) {
        setFilterErrors((prev: any) => ({ ...prev, startDate: "Required" }));
        valid = false;
      } else {
        setFilterErrors((prev: any) => ({ ...prev, startDate: undefined }));
      }

      if (endDate == null) {
        setFilterErrors((prev: any) => ({ ...prev, endDate: "Required" }));
        valid = false;
      } else {
        setFilterErrors((prev: any) => ({ ...prev, endDate: undefined }));
      }
    } else if (startDate > endDate) {
      setFilterErrors((prev: any) => ({ ...prev, startDate: "End date must be after start date", endDate: "End date must be after start date" }));
      valid = false;
    } else {
      setFilterErrors((prev: any) => ({ ...prev, startDate: undefined, endDate: undefined }));
    }

    return valid;
  };

  const handleCloseFilters = () => {
    if (!filtersLoading && isValidFilters()) {
      setFiltersOpen(false);
      updateQueryParams();
    }
  };

  const getPlaceTitle = (place: any, type: any, country: any) => {
    let title = "Nothing Selected";
    if (place && place.label) title = place.label;
    else if (type && type.label) title = type.label;

    if (country && country.label) title = title + " at " + country.label;

    return title;
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={handleCloseFilters}
        onHandleClick={() => {
          if (!filtersLoading) setFiltersOpen(!filtersOpen);
        }}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                if (!filtersLoading) setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <LoadingContainer loading={filtersLoading}>
              <form noValidate onSubmit={(e) => e.preventDefault()}>
                <label>Start Place</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="startPlace"
                    isDisabled={startPlaceType != null || startCountry != null}
                    defaultOptions={true}
                    isClearable={true}
                    isError={filterErrors.startPlace}
                    isSearchable={true}
                    value={startPlace}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("tripplaces", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setStartPlace(selected);
                      } else {
                        setStartPlace(null);
                      }
                      setFilterErrors((prev: any) => ({ ...prev, startPlace: undefined, startPlaceType: undefined }));
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.startPlace}>{filterErrors.startPlace}</FormError>
                </FilterInputContainer>
                <label>Start Place Type</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="startPlaceType"
                    isDisabled={startPlace != null}
                    defaultOptions={true}
                    isClearable={true}
                    isError={filterErrors.startPlaceType}
                    isSearchable={true}
                    value={startPlaceType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setStartPlaceType(selected);
                      } else {
                        setStartPlaceType(null);
                      }
                      setFilterErrors((prev: any) => ({ ...prev, startPlace: undefined, startPlaceType: undefined }));
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.startPlaceType}>{filterErrors.startPlaceType}</FormError>
                </FilterInputContainer>
                <label>Start Country</label>
                <FilterInputContainer>
                  <Select
                    name="startCountry"
                    isDisabled={startPlace != null}
                    isClearable={true}
                    isError={filterErrors.startCountry}
                    isSearchable={true}
                    value={startCountry}
                    options={countryList}
                    onChange={(selected: any) => {
                      if (selected) {
                        setStartCountry(selected);
                      } else {
                        setStartCountry(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.startCountry}>{filterErrors.startCountry}</FormError>
                </FilterInputContainer>
                <label>Visited Country</label>
                <FilterInputContainer>
                  <Select
                    name="visitedCountry"
                    isClearable={true}
                    isError={filterErrors.visitedCountry}
                    isSearchable={true}
                    value={visitedCountry}
                    options={countryList}
                    onChange={(selected: any) => {
                      if (selected) {
                        setVisitedCountry(selected);
                      } else {
                        setVisitedCountry(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.visitedCountry}>{filterErrors.visitedCountry}</FormError>
                </FilterInputContainer>
                <label>End Place</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="endPlace"
                    isDisabled={endPlaceType != null || endCountry != null}
                    defaultOptions={true}
                    isClearable={true}
                    isError={filterErrors.endPlace}
                    isSearchable={true}
                    value={endPlace}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("tripplaces", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setEndPlace(selected);
                      } else {
                        setEndPlace(null);
                      }
                      setFilterErrors((prev: any) => ({ ...prev, endPlace: undefined, endPlaceType: undefined }));
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.endPlace}>{filterErrors.endPlace}</FormError>
                </FilterInputContainer>
                <label>End Place Type</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="endPlaceType"
                    isDisabled={endPlace != null}
                    defaultOptions={true}
                    isClearable={true}
                    isError={filterErrors.endPlaceType}
                    isSearchable={true}
                    value={endPlaceType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setEndPlaceType(selected);
                      } else {
                        setEndPlaceType(null);
                      }
                      setFilterErrors((prev: any) => ({ ...prev, endPlace: undefined, endPlaceType: undefined }));
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.endPlaceType}>{filterErrors.endPlaceType}</FormError>
                </FilterInputContainer>
                <label>End Country</label>
                <FilterInputContainer>
                  <Select
                    name="endCountry"
                    isDisabled={endPlace != null}
                    isClearable={true}
                    isError={filterErrors.endCountry}
                    isSearchable={true}
                    value={endCountry}
                    options={countryList}
                    onChange={(selected: any) => {
                      if (selected) {
                        setEndCountry(selected);
                      } else {
                        setEndCountry(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                  <FormError error={filterErrors.endCountry}>{filterErrors.endCountry}</FormError>
                </FilterInputContainer>
                <label>Start Date</label>
                <FilterInputContainer>
                  <FlatpickrPicker
                    name="startDate"
                    enableTime={true}
                    error={filterErrors.startDate}
                    value={startDate}
                    clearable={false}
                    onChange={(d: any) => setStartDate(d[0])}
                    onClose={(d: any) => setStartDate(d[0])}
                    options={{ maxDate: Date.now() }}
                  />
                  <FormError error={filterErrors.startDate}>{filterErrors.startDate}</FormError>
                </FilterInputContainer>
                <label>End Date</label>
                <FilterInputContainer>
                  <FlatpickrPicker
                    name="endDate"
                    enableTime={true}
                    error={filterErrors.endDate}
                    value={endDate}
                    clearable={false}
                    onChange={(d: any) => setEndDate(d[0])}
                    onClose={(d: any) => setEndDate(d[0])}
                    options={{ maxDate: Date.now() }}
                  />
                  <FormError error={filterErrors.endDate}>{filterErrors.endDate}</FormError>
                </FilterInputContainer>
                {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
              </form>
            </LoadingContainer>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn onClick={handleCloseFilters}>Apply Filters</PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Route Audit" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Route Audit</Title>
              <Subtitle>Review how often certain routes occur and how long they take</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef, long_datetime), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Route Audit.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock>
                <StatLabel>Start{startPlace ? " Place" : startPlaceType ? " Place Type" : ""}</StatLabel>
                <StatValue title={getPlaceTitle(startPlace, startPlaceType, startCountry)}>{getPlaceTitle(startPlace, startPlaceType, startCountry)}</StatValue>
              </StatBlock>
              {visitedCountry && visitedCountry.label && (
                <StatBlock>
                  <StatLabel>Visits</StatLabel>
                  <StatValue title={visitedCountry.label}>{visitedCountry.label}</StatValue>
                </StatBlock>
              )}
              <StatBlock>
                <StatLabel>End{endPlace ? " Place" : endPlaceType ? " Place Type" : ""}</StatLabel>
                <StatValue title={getPlaceTitle(endPlace, endPlaceType, endCountry)}>{getPlaceTitle(endPlace, endPlaceType, endCountry)}</StatValue>
              </StatBlock>
              <StatBlock>
                <StatLabel>Start Date</StatLabel>
                <StatValue title={startDate ? moment.unix(startDate / 1000).format(long_datetime) : "No Date Selected"}>
                  {startDate ? moment.unix(startDate / 1000).format(short_datetime) : "No Date Selected"}
                </StatValue>
              </StatBlock>
              <StatBlock>
                <StatLabel>End Date</StatLabel>
                <StatValue title={endDate ? moment.unix(endDate / 1000).format(long_datetime) : "No Date Selected"}>
                  {endDate ? moment.unix(endDate / 1000).format(short_datetime) : "No Date Selected"}
                </StatValue>
              </StatBlock>
            </StatPanel>
            {(data.meta.trips != null || data.meta.sensors != null) && (
              <StatPanel>
                <StatBlock style={{ width: "260px" }}>
                  <StatLabel>Total Trips</StatLabel>
                  <StatValue>{data.meta.trips}</StatValue>
                </StatBlock>
                <StatBlock style={{ width: "260px" }}>
                  <StatLabel>Total {kegsOrTrackers("Kegs", "Trackers")}</StatLabel>
                  <StatValue>{data.meta.sensors}</StatValue>
                </StatBlock>
              </StatPanel>
            )}
            {(data.meta.minMinutes || data.meta.avgMinutes || data.meta.maxMinutes) && (
              <StatPanel>
                {data.meta.minMinutes != null && (
                  <StatBlock style={{ width: "420px" }}>
                    <StatLabel>Min Duration</StatLabel>
                    <StatValue>{humaniseMinutes(data.meta.minMinutes)}</StatValue>
                  </StatBlock>
                )}
                {data.meta.avgMinutes != null && (
                  <StatBlock style={{ width: "420px" }}>
                    <StatLabel>Avg Duration</StatLabel>
                    <StatValue>{humaniseMinutes(data.meta.avgMinutes)}</StatValue>
                  </StatBlock>
                )}
                {data.meta.maxMinutes != null && (
                  <StatBlock style={{ width: "420px" }}>
                    <StatLabel>Max Duration</StatLabel>
                    <StatValue>{humaniseMinutes(data.meta.maxMinutes)}</StatValue>
                  </StatBlock>
                )}
              </StatPanel>
            )}
            <Panel>
              <Table
                loading={dataLoading}
                style={{ clear: "both" }}
                data={data.trips}
                filterable={true}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
            {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
          </LoadingContainer>
        </div>
      </PageContainer>
      {placeList?.length > 0 && (
        <PlaceListModal
          size="lg"
          data={placeList}
          columns={["id", "name", "placeGroup", "placeTags", "address", "city", "state", "postcode", "country", "startDate", "endDate", "duration"]}
          modalOpen={placeListModalOpen}
          setModalOpen={setPlaceListModalOpen}
          onClose={() => setPlaceList([])}
        />
      )}
    </>
  );
};

export default RouteAudit;
