import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce/lib";
import { deleteBatch, fetchBatch } from "../../services/editBatch";
import { getPlaceBatchesTableSettings, placeBatchesTableDefaults, savePlaceBatchesTableSettings } from "../../services/localStorage";
import MoreIcon from "../../svgs/MoreIcon";
import addOrUpdate from "../../util/addOrUpdate";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { getTableFilters } from "../../util/urlParamFilters";
import BeerBatchKegsListModal from "../BeerBatchKegsListModal";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditBeerBatchModal from "../EditBeerBatchModal";
import { PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type Batch = {
  placeName: string;
  beerName: string;
  kegsFull: number;
  kegsEmptied: number;
  dateCreated: number;
  notes: string;
};

// if placeId is passed in, this table is being used on the place page so we don't display as much information.
// if no placeId is passed in, this table is being used on the beer page.
const BeerBatchesTable: FC<any> = ({ placeId }) => {
  const { color, short_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateCreated", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedBatch, setSelectedBatch] = useState<any>(null);
  const [selectedKegList, setSelectedKegList] = useState<any>([]);
  const [kegListTitle, setKegListTitle] = useState<string>("");
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [kegListModalOpen, setKegListModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getPlaceBatchesTableSettings() ? getPlaceBatchesTableSettings().pageSize : 10,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchBatches = () => {
    setDataLoading(true);
    setDataErr("");

    fetchBatch(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) }, placeId)
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataErr("");
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);

    fetchBatch(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) }, placeId)
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Beer Batches List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = ["Beer", "Full Kegs", "Emptied Kegs", "Date Created", "Notes"];

    if (!placeId) headers.unshift("Place");

    return [
      headers,
      ...data.map((row: any) => {
        const data = [row.beerName, row.kegsFull.length, row.kegsEmptied.length, moment.unix(row.dateCreated).format(short_datetime), row.notes];

        if (!placeId) data.unshift(row.placeName);

        return data;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<Batch>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedBatch(row.original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Batch
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedBatch(row.original);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete Batch
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Batch</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Delete Batch</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      ...(!placeId
        ? [
            {
              header: "Place",
              accessorKey: "placeName",
              cell: (props: any) => (
                <Link title={props.getValue()} to={`/places/${props.row.original.placeId}`}>
                  {props.getValue()}
                </Link>
              ),
              meta: {
                filterType: "string",
              },
              filterFn: undefined,
              size: 180,
            },
          ]
        : []),
      {
        accessorKey: "beerName",
        header: "Beer",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "autoComplete",
          filterKey: "beerName",
          selectKey: "label",
          loadOptionsKey: "beers",
        },
        filterFn: undefined,
        size: 160,
      },
      {
        header: "Full Kegs",
        accessorKey: "kegsFullCount",
        cell: (props: any) => (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setSelectedKegList(props.row.original.kegsFull);
              setKegListTitle("Full Kegs");
              setKegListModalOpen(true);
            }}
            title={props.getValue()}
          >
            {props.getValue()}
          </span>
        ),
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        header: "Emptied Kegs",
        accessorKey: "kegsEmptiedCount",
        cell: (props: any) => (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setSelectedKegList(props.row.original.kegsEmptied);
              setKegListTitle("Emptied Kegs");
              setKegListModalOpen(true);
            }}
            title={props.getValue()}
          >
            {props.getValue()}
          </span>
        ),
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        header: "Date Created",
        accessorKey: "dateCreated",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 175,
      },
      {
        header: "Notes",
        accessorKey: "notes",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchBatches}
        fetchCsv={fetchCsv}
        defaultTableSettings={placeBatchesTableDefaults}
        getTableSettings={getPlaceBatchesTableSettings}
        saveTableSettings={savePlaceBatchesTableSettings}
        dataTypeName="Batches"
        emptyDataMsg="Create a batch above to get started"
        TableButtons={
          <div>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedBatch(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedBatch(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {editModalOpen && (
        <EditBeerBatchModal
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          batch={selectedBatch}
          placeId={placeId}
          onCreate={(response: any) => {
            if (response) {
              setData((prev: any) => [...prev, response]);
            }
          }}
          onUpdate={(response: any) => {
            setData((prev: any) => addOrUpdate(prev, response, "id"));
          }}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Beer Batch"
          body={<span>Are you sure you want to delete this beer batch?</span>}
          successMsg="Beer Batch Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedBatch.id))}
          onClose={() => setSelectedBatch(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteBatch}
          serviceParams={[selectedBatch.id]}
        />
      )}
      {<BeerBatchKegsListModal modalOpen={kegListModalOpen} setModalOpen={setKegListModalOpen} data={selectedKegList} title={kegListTitle} />}
    </>
  );
};

export default BeerBatchesTable;
