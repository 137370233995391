import moment from "moment";
import { FC, useContext, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { ThemeContext } from "styled-components";
import Cross from "../../svgs/Cross";
import { ClearButton } from "./styles";

const FlatpickrPicker: FC<any> = ({ value, onClose, onChange, onClear, error, clearable = true, dateFormat, enableTime, options, placeholder }) => {
  const flatpickrRef = useRef<any>(null);

  const { short_datetime } = useContext(ThemeContext);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Flatpickr
        data-enable-time={enableTime}
        ref={flatpickrRef}
        value={value}
        onChange={onChange}
        onClose={onClose}
        options={{ ...options, formatDate: (date: any) => moment(date).format(dateFormat ?? short_datetime) }}
        className={error ? "flatpickr-input-error" : "flatpickr-input"}
        placeholder={placeholder}
      />
      {clearable && value != null && (
        <ClearButton
          onClick={() => {
            if (flatpickrRef && flatpickrRef.current && flatpickrRef.current.flatpickr) {
              flatpickrRef.current.flatpickr.clear();
              if (onClear) onClear();
            }
          }}
        >
          <Cross />
        </ClearButton>
      )}
    </div>
  );
};

export default FlatpickrPicker;
