import React from "react";
import AirFiltrationIcon from "../svgs/AirFiltrationIcon";
import CA1Icon from "../svgs/CA1Icon";
import CA2Icon from "../svgs/CA2Icon";
import CH1Icon from "../svgs/CH1Icon";
import CH2Icon from "../svgs/CH2Icon";
import EngineIcon from "../svgs/EngineIcon";
import IKegKegIcon from "../svgs/IKegKegIcon";
import KegLinkIcon from "../svgs/KegLinkIcon";
import QrScanIcon from "../svgs/QrScanIcon";
import wiliotTag from "../imgs/wiliot-tag-front.png";

const getSensorIcon = (productId: number, productName: string, color: any) => {
  switch (productId) {
    case 0:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <QrScanIcon outline={color.font_bold[2]} />
        </div>
      );

    case 1:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CA1Icon />
        </div>
      );

    case 2:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CA2Icon />
        </div>
      );

    case 3:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CH1Icon />
        </div>
      );

    case 4:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CH2Icon />
        </div>
      );

    case 5:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <EngineIcon fill={color.font_bold[2]} />
        </div>
      );

    case 6:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    case 7:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    case 8:
      return (
        <div
          title={productName}
          style={{
            width: "140px",
            height: "70px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    case 9:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    case 10:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <IKegKegIcon />
        </div>
      );

    case 19:
      return (
        <div
          title={productName}
          style={{
            width: "80px",
          }}
        >
          <img width="100%" src={wiliotTag} alt="Wiliot" />
        </div>
      );

    default:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <QrScanIcon outline={color.font_bold[2]} />
        </div>
      );
  }
};

export default getSensorIcon;
